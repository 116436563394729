import { createTheme } from '@mui/material/styles';

const EarPhysicsTheme = createTheme({
  palette: {
    primary: {
      main: '#1c1c1e',
      contrastText: "#fff",
    },
    secondary: {
      main: '#f1f1f2',
      contrastText: "#333344",
    },
    background: {
      paper: "#252525",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
    divider: '#fff',
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      color: "#fff",
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
      color: "#fff",
    },
    h3: {
      fontWeight: 500,
      fontSize: '2.5rem',
      color: "#fff",
    },
    h4: {
      fontWeight: 500,
      fontSize: '2rem',
      color: "#fff",
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.5rem',
      color: "#fff",
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.25rem',
      color: "#fff",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      color: "#fff",
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '0.875rem',
      color: "#fff",
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      color: "#fff",
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.875rem',
      color: "#fff",
    },
    button: {
      fontWeight: 500,
      textTransform: 'uppercase',
      color: "#fff",
    },
    caption: {
      fontWeight: 300,
      fontSize: '0.75rem',
      color: "#fff",
    },
    overline: {
      fontWeight: 500,
      fontSize: '0.625rem',
      color: "#fff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#121213',
          color: "#fff",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#2c2c2e",
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(241, 241, 242, 0.7)"
          },
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(241, 241, 242, 0.9)"
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f1f1f2",
            borderWidth: 2
          }
        },
        input: {
          color: "#fff",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c1c1e',
          color: "#fff",
        },
      },
    },
  },
  spacing: 8,
});

export default EarPhysicsTheme;
