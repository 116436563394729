import React from 'react';
import PageLayout from '../components/PageLayout';

const termsOfServiceContent = `
Last updated: 11/01/2023

Welcome to EarPhysics.com (the "Site"), owned and operated by Ear Physics, your go-to source for personalized in-ear monitors and hearing solutions. As we sell our products directly to consumers, we prioritize transparency regarding how we handle the information of our valued customers.

COLLECTION OF INFORMATION

a. Information You Provide: When you purchase from our Site, we collect personal information such as your name, shipping address, contact information, and payment details to process your order and keep you updated on its status.

b. Customer Service Interaction: Should you contact our customer service, we may collect additional information to help resolve any issues and improve your shopping experience.

c. Automatic Data Collection: As you navigate through our Site, we automatically collect certain information such as your IP address, browser specifics, and browsing behavior to enhance our Site's functionality and your user experience.

d. Cookies and Tracking Technologies: We employ cookies and similar technologies to understand your preferences, improve your shopping experience, and provide tailored product recommendations.

USE OF INFORMATION

We use your information to:

- Process, fulfill, and follow-up on orders
- Provide personalized customer support
- Improve our product offerings and customer experience
- Send you marketing communications with your consent
- Conduct analytics and measure the effectiveness of our advertising campaigns

SHARING OF INFORMATION

We take your privacy seriously and only share your information as necessary to process your order, comply with the law, or protect our rights. We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.

ORDER PROCESSING AND ADVERTISING PARTNERS

To offer you a seamless shopping experience, we partner with trusted third-party services for payment processing and order fulfillment. Our advertising partners may use cookies to deliver Ear Physics ads tailored to your interests on other platforms.

DATA SECURITY

We implement a variety of security measures to maintain the safety of your personal information. Our Site is equipped with SSL technology, and all sensitive/credit information supplied is encrypted during transmission.

CHANGES TO THIS PRIVACY POLICY

Ear Physics may occasionally update this policy to reflect changes in our practices. The latest version will always be posted on our Site, with the effective date at the top. Your continued use of our Site following any changes indicates your agreement to the updated terms.

YOUR ACCEPTANCE OF THESE TERMS

By ordering from EarPhysics.com, you acknowledge and agree to this Privacy Policy. If you have any questions about this policy or your personal data, please contact us.

Please note: This content is provided as a template and should be reviewed and customized as necessary to ensure it meets legal requirements and aligns with the specific privacy practices of Ear Physics.
`;

const PrivacyPage = () => {
  const sections = [
    {
      title: 'User Data and Privacy Guidelines',
      subheader: 'Please Read Carefully',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {termsOfServiceContent}
        </div>
      ),
    },
  ];

  return (
    <PageLayout pageTitle="Privacy Policy" sections={sections}>
      {/* Additional content like updates, amendments, etc., can be added here */}
    </PageLayout>
  );
};

export default PrivacyPage;
