import React, { useState } from "react";
import { Typography, Divider, Box, Container } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const PageLayout = ({ children, pageTitle, sections }) => {
  const [loaded, setLoaded] = useState(false);

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: !loaded ? "black" : "transparent",
    opacity: loaded ? 1 : 0,
    transition: "opacity 1s ease, background-color 1s ease",
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Navbar />

      <Container
        maxWidth="lg"
        sx={{
          margin: "auto",
          pt: { xs: 8, sm: 9, md: 3 },
          pb: { xs: 2, md: 3 },
          px: { xs: 2, md: 3 },
        }}
      >
        {pageTitle && (
          <Typography
            variant="h1"
            sx={{
              color: "white",
              textAlign: "center",
              paddingBottom: "10px",
              paddingTop: { xs: 8, sm: 12, md: 15 },
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              fontWeight: "bold",
              letterSpacing: "3px",
            }}
          >
            {pageTitle}
          </Typography>
        )}
        {children}
        {sections &&
          sections.map((section, index) => (
            <Box key={index} sx={{ py: { xs: 5, sm: 10, md: 15 } }}>
              <Divider sx={{ backgroundColor: "#cdcdcd", my: 6 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column-reverse",
                    sm: section.image
                      ? index % 2 === 0
                        ? "row"
                        : "row-reverse"
                      : "column",
                  },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    p: "30px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "white", paddingBottom: "10px" }}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    {section.subheader}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", paddingTop: "10px" }}
                  >
                    {section.content}
                  </Typography>
                </Box>

                {section.image && (
                  <Box
                    sx={{
                      flex: 1,
                      position: "relative",
                      zIndex: 1,
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: { xs: "40%", sm: "0" },
                        bottom: { xs: "30%", sm: "0" },
                        backgroundImage: {
                          xs: `linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.9) 100%),
                       radial-gradient(at 50% bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,0.9) 100%)`,
                          sm: "none",
                        },
                        display: "block",
                      },
                    }}
                  >
                    <LazyLoadImage
                      src={section.image}
                      alt="Section Image"
                      effect="blur"
                      onLoad={handleOnLoad}
                      style={imageStyle}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
      </Container>

      <Footer />
    </Box>
  );
};

export default PageLayout;
