import React from 'react';
import PageLayout from '../components/PageLayout';

// Plain text for Terms of Service
const termsOfServiceContent = `These Terms of Service ("Terms") govern your access to and use of EarPhysics.com’s e-commerce platform, which includes our website, any subdomains thereof, mobile applications, and any other websites through which our products are offered (collectively, the "Site"), and all associated services (collectively, "Services"). By using the Site and Services, you agree to comply with and be legally bound by these Terms. If you do not agree to these Terms, you must refrain from using our Site and Services.

Acceptance of Terms

By accessing or using the Site and Services, you confirm that you have read, understood, and agree to be bound by these Terms. If you are using the Site and Services on behalf of a company, organization, or other entity, you represent and warrant that you are authorized to agree to these Terms on behalf of that entity and bind them to these Terms.

Use of Services

The Company provides a platform for users to browse and purchase audio technology products. You agree to (a) provide accurate, current, and complete information as may be prompted by any registration forms on the Site ("Registration Data"); (b) maintain the security of your password and identification; (c) maintain and promptly update the Registration Data, and any other information you provide to the Company, and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to the Company.

Order Processing

Ear Physics reserves the right, at our sole discretion, to refuse or cancel any order for any reason. Situations that may result in your order being canceled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order.

Payment Terms

For each product you order on the Site, you agree to pay the price applicable for the product as of the time you submitted your order and any applicable taxes. All payments will be processed by our third-party payment processor, and by using such payment services, you agree to their terms of use.

User Conduct

You agree not to use the Site or Services to conduct any activity that would constitute a civil or criminal offense or violate any law. You agree not to attempt to interfere with the Site's or Service’s networks or security features.

Intellectual Property Rights

All content on the Site, including but not limited to text, graphics, logos, icons, images, audio clips, and software, is the property of Ear Physics or its content suppliers and is protected by United States and international copyright laws. You agree not to reproduce, duplicate, copy, sell, resell, or exploit for any commercial purposes, any aspect of the Site or Services.

Disclaimer of Warranties and Limitation of Liability

The Company provides the Site and Products on an "as provided" basis. The Company does not make any other warranties, express or implied, about the operation of the Site or the information, content, materials, or products included on the Site.

Governing Law

These Terms shall be governed by and construed in accordance with the laws of the State of Michigan, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within Ann Arbor, Michigan.

Modifications to the Service and Prices

Prices for our products are subject to change without notice. We reserve the right to modify or discontinue the Service (or any part thereof) without notice at any time.

Contact Information

Questions about the Terms of Service should be sent to support@earphysics.com.

Conclusion

Your use of this website and/or purchase of products indicates your agreement to these Terms. Your statutory Consumer Rights are unaffected.

`
const TermsOfServicePage = () => {
  const sections = [
    {
      title: 'Terms of Service',
      subheader: 'In using our website you are deemed to have read and agreed to the following terms and conditions:',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {termsOfServiceContent}
        </div>
      ),
    },
  ];

  return (
    <PageLayout pageTitle="Terms of Service" sections={sections}>
      {/* Additional content like updates, amendments, etc., can be added here */}
    </PageLayout>
  );
};

export default TermsOfServicePage;
