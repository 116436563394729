import React from 'react';
import { Box, Typography, Button, IconButton, Snackbar } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import HeightIcon from '@mui/icons-material/Height';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { toTitleCase } from '../tools/Strings';

const HearingTestBasic = ({
    onStartTest,
    onDecreaseVolume,
    onMarkThreshold,
    testStarted,
    tone,
    ear,
    testAdvanced,
    extendPoints,
    pulseWave,
    extendedActive,
    pulseActive,
    onExtendedDeactivate,
    onPulseDeactivate
}) => {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleExtendedClick = () => {
        if (extendedActive) {
            onExtendedDeactivate();
            setSnackbarMessage('Extended Points Deactivated');
        } else {
            extendPoints();
            setSnackbarMessage('Extended Points Activated');
        }
        setSnackbarOpen(true);
    };

    const handlePulseClick = () => {
        if (pulseActive) {
            onPulseDeactivate();
            setSnackbarMessage('Pulse Mode Deactivated');
        } else {
            pulseWave();
            setSnackbarMessage('Pulse Mode Activated');
        }
        setSnackbarOpen(true);
    };

    return (
        <Box sx={{ p: 4, textAlign: 'center', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4">Testing {toTitleCase(ear || '')} Ear</Typography>
                <Typography variant="h5" sx={{ mt: 2 }}>{tone ? `${tone} Hz` : '-'}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                {testStarted && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Listen carefully for the sound. Let us know when you can no longer hear it.
                        </Typography>

                        <Button variant="contained" onClick={onDecreaseVolume} sx={{ mb: 2 }}>
                            I hear the sound
                        </Button>
                        <Button variant="contained" onClick={onMarkThreshold}>
                            I don't hear it
                        </Button>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 8 }}>
                            <IconButton onClick={testAdvanced} sx={{ color: 'white', mx: 4 }}>
                                <DisplaySettingsIcon style={{ fontSize: 42 }} />
                            </IconButton>
                            <IconButton
                                onClick={handleExtendedClick}
                                sx={{
                                    color: extendedActive ? 'white' : 'inherit',
                                    mx: 4,
                                    backgroundColor: extendedActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                                    borderRadius: '50%',
                                }}
                            >
                                <HeightIcon style={{ transform: 'rotate(90deg)', fontSize: 42 }} />
                            </IconButton>
                            <IconButton
                                onClick={handlePulseClick}
                                sx={{
                                    color: pulseActive ? 'white' : 'inherit',
                                    mx: 4,
                                    backgroundColor: pulseActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                                    borderRadius: '50%',
                                }}
                            >
                                <LinearScaleIcon style={{ fontSize: 42 }} />
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {!testStarted && (
                    <Button variant="contained" onClick={onStartTest} sx={{ mb: 4 }}>
                        Begin Test
                    </Button>
                )}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default HearingTestBasic;
