import React from "react";
import { Container, Typography, Link, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ bgcolor: "black", color: "white", pt: 6, pb: 3 }}
    >
      <Container>
        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 3, fontSize: { xs: "1rem", sm: "1.25rem" } }}
        >
          © 2023 Ear Physics - Manufactured with &#9825; in Michigan
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-evenly',
            alignItems: 'center',
            mb: 3,
          }}
        >
          {/* Navigation Links */}
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/" color="inherit" underline="hover">Home</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/products" color="inherit" underline="hover">Products</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/news" color="inherit" underline="hover">News</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/contact" color="inherit" underline="hover">Contact</Link>
          </Typography>
        </Box>

        <Typography
          variant="caption"
          display="block"
          align="center"
          sx={{ color: 'white', fontSize: '0.875rem' }}
        >
          For more information, please review our
          <Link href="/privacy" underline="hover" color="inherit" sx={{ mx: 0.5 }}>
            Privacy Policy
          </Link>
          and
          <Link href="/terms" underline="hover" color="inherit" sx={{ mx: 0.5 }}>
            Terms & Conditions.
          </Link>
          &nbsp;&nbsp;US Patent 11,641,183, other patents pending.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
