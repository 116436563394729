import React from 'react';
import PageLayout from '../components/PageLayout';

import LiveImage from '../assets/images/live.png';
import ManConcertImage from '../assets/images/man-concert.png';
import ManPlanetsImage from '../assets/images/man-planets.png';
import MusicImage from '../assets/images/music.png';
import NucleasImage from '../assets/images/nucleas.png';
import OverEarImage from '../assets/images/over-ear.png';
import PhysicsImage from '../assets/images/physics.png';

const sections = [
  {
    title: 'ACOUSTIC ENGINEERING BREAKTHROUGHS',
    subheader: "Advancing Sound Experience",
    content: (
      <div>
        <p>
          Explore the latest breakthroughs in acoustic engineering from Ear Physics. 
          Learn how we’re enhancing the listening experience with state-of-the-art in-ear monitors tailored to individual hearing profiles.
          {/* <a href="/acoustic-engineering-breakthroughs">Read more...</a> */}
        </p>
      </div>
    ),
    image: NucleasImage,
  },
  {
    title: 'COMPANY UPDATES',
    subheader: "Milestones and Innovations",
    content: (
      <div>
        <p>
          Stay informed about Ear Physics’ journey. From our latest product launches to breakthroughs in hearing correction technology, follow our strides towards sonic perfection.
          {/* <a href="/company-updates">Read more...</a> */}
        </p>
      </div>
    ),
    image: PhysicsImage,
  },
  {
    title: 'HEARING HEALTH INSIGHTS',
    subheader: "Expert Knowledge",
    content: (
      <div>
        <p>
          Dive into expert-written articles and research that provide deep insights into hearing health. Understand the impact of sound quality on daily life and the innovations that are changing the future of hearing.
          {/* <a href="/hearing-health-insights">Read more...</a> */}
        </p>
      </div>
    ),
    image: ManPlanetsImage,
  },
  {
    title: 'COMMUNITY EVENTS',
    subheader: "Join the Conversation",
    content: (
      <div>
        <p>
          Discover events and webinars hosted by Ear Physics. Meet our team, experience our products, and discuss the future of sound technology with industry leaders.
          {/* <a href="/community-events">Read more...</a> */}
        </p>
      </div>
    ),
    image: LiveImage,  // Updated image
  },
];

const NewsPage = () => {
  return (
    <PageLayout pageTitle="Hearing Innovation News" sections={sections}>
      {/* Additional content or interactive elements like a news feed, subscription form, etc. */}
    </PageLayout>
  );
};

export default NewsPage;
