import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import HearingTestBasic from '../components/HearingTestBasic';
import HearingTestAdvanced from '../components/HearingTestAdvanced';
import { useNavigate } from 'react-router-dom';
import ImprintStore from '../stores/ImprintStore';

const HearingTest = () => {
    const [testStarted, setTestStarted] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);
    const [currentLevel, setCurrentLevel] = useState(50);
    const [ear, setEar] = useState('left');
    const [withNoise, setWithNoise] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const testFrequencies = [250, 500, 1000, 2000, 4000, 6000, 8000];
    const navigate = useNavigate();

    const audioContextRef = useRef(null);
    const oscillatorRef = useRef(null);
    const whiteNoiseSourceRef = useRef(null);

    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (testStarted) {
            playTestTone();
        }
    }, [currentFrequencyIndex, currentLevel, ear, withNoise, testStarted]);

    const speak = (text, callback = null) => {
        if ('speechSynthesis' in window) {
            const msg = new SpeechSynthesisUtterance(text);
            msg.lang = 'en-US';
            msg.onend = callback;
            window.speechSynthesis.speak(msg);
        }
    };

    const playTone = (frequency, level) => {
        const audioContext = audioContextRef.current;
        if (!audioContext) return;

        oscillatorRef.current = audioContext.createOscillator();
        const gainNode = audioContext.createGain();
        const panner = audioContext.createStereoPanner();

        panner.pan.value = ear === 'left' ? -1 : 1;
        oscillatorRef.current.frequency.value = frequency;
        gainNode.gain.value = level / 100;

        oscillatorRef.current.connect(gainNode);
        gainNode.connect(panner);
        panner.connect(audioContext.destination);

        oscillatorRef.current.start();
        setIsPlaying(true);
    };

    const stopTone = () => {
        if (oscillatorRef.current) {
            oscillatorRef.current.stop();
            oscillatorRef.current.disconnect();
            setIsPlaying(false);
        }
    };

    const playWhiteNoise = (currentEar) => {
        const audioContext = audioContextRef.current;
        if (!audioContext) return;
    
        whiteNoiseSourceRef.current = audioContext.createBufferSource();
        const bufferSize = audioContext.sampleRate * 3;  // 3 seconds of buffer
        const buffer = audioContext.createBuffer(1, bufferSize, audioContext.sampleRate);
        const data = buffer.getChannelData(0);
    
        for (let i = 0; i < bufferSize; i++) {
            data[i] = Math.random() * 2 - 1;  // Generate white noise
        }
    
        whiteNoiseSourceRef.current.buffer = buffer;
        whiteNoiseSourceRef.current.loop = true;  // Enable looping
    
        const gainNode = audioContext.createGain();
        const panner = audioContext.createStereoPanner();
    
        panner.pan.value = currentEar === 'left' ? -1 : 1;
        gainNode.gain.value = currentLevel / 100;
    
        whiteNoiseSourceRef.current.connect(gainNode);
        gainNode.connect(panner);
        panner.connect(audioContext.destination);
    
        whiteNoiseSourceRef.current.start();
    };

    const stopWhiteNoise = () => {
        if (whiteNoiseSourceRef.current) {
            whiteNoiseSourceRef.current.stop();
            whiteNoiseSourceRef.current.disconnect();
            setIsPlaying(false);
        }
    };

    const resetSound = () => {
        setCurrentFrequencyIndex(0);
        setCurrentLevel(50);
        stopWhiteNoise();
        setTestStarted(true);
    }

    const advanceTest = () => {
        if (currentFrequencyIndex < testFrequencies.length - 1) {
            setCurrentLevel(50);
            setCurrentFrequencyIndex(currentFrequencyIndex + 1);
            setTestStarted(true);
        } else {
            if (ear === 'right') {
                if (withNoise) {
                    endTestCycle();
                } else {
                    setEar('left')
                    setWithNoise(true);
                    resetSound();
                }
            } else {
                setEar('right');
                resetSound();
            }
        }
    };

    const handleDecreaseVolume = () => {
        setCurrentLevel(prevLevel => {
            const newLevel = Math.max(prevLevel - 10, 0);
            stopTone();
            stopWhiteNoise();
            setTimeout(() => {
                playTestTone(newLevel);  // Pass the new level directly to the play function
            }, 100);  // Delay to ensure stop functions complete
            return newLevel;
        });
    };
    
    const handleLevelChange = (event, newValue) => {
        setCurrentLevel(newValue);
    };
    
    const handlePrevFrequency = () => {
        if (currentFrequencyIndex > 0) {
            setCurrentFrequencyIndex(currentFrequencyIndex - 1);
            setTimeout(() => playTestTone(), 500);
        }
    };
    
    const handleToggleTestMode = () => {
        setAdvanced(!advanced);
    };
    

    const handleStartTest = () => {
        resetSound();
        setEar('left');
        setWithNoise(false);
        speak('Starting the audio response evaluation', () => playTestTone());
    };

    const playTestTone = (level = currentLevel) => {
        stopTone();  // Stop any previous tone that might be playing
        const frequency = testFrequencies[currentFrequencyIndex];
        playTone(frequency, level);  // Always play the tone
        
        if (withNoise) {
            stopWhiteNoise();
            playWhiteNoise(ear);  // Additionally play white noise if required
        }
    };

    const handleMarkThresholdAndAdvance = () => {
        const imprint = {
            frequency: testFrequencies[currentFrequencyIndex],
            level: currentLevel,
            ear: ear,
            withNoise: withNoise
        };
        ImprintStore.addImprint(imprint);
        advanceTest();
    };

    const endTestCycle = () => {
        speak('Test completed', () => {
            setTestStarted(false);
            setWithNoise(false);
            setEar('left');
            setCurrentFrequencyIndex(0);
            ImprintStore.saveImprints();
            navigate('/view-imprint');
        });
    };

    return (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
            {!advanced ? (
                <HearingTestBasic
                    onStartTest={handleStartTest}
                    onDecreaseVolume={handleDecreaseVolume}
                    onMarkThreshold={handleMarkThresholdAndAdvance}
                    testStarted={testStarted}
                    tone={testFrequencies[currentFrequencyIndex]}
                    currentLevel={currentLevel}
                    ear={ear}
                />
            ) : (
                <HearingTestAdvanced
                    onLevelChange={handleLevelChange}
                    onNextFrequency={handleMarkThresholdAndAdvance}
                    onPrevFrequency={handlePrevFrequency}
                    onToggleTestMode={handleToggleTestMode}
                    isFirstFrequency={currentFrequencyIndex === 0}
                    isLastFrequency={currentFrequencyIndex === testFrequencies.length - 1}
                    frequency={testFrequencies[currentFrequencyIndex]}
                    level={currentLevel}
                />
            )}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: 2,
                    zIndex: 9999,
                }}
            >
                <Typography variant="body1">Frequency: {testFrequencies[currentFrequencyIndex]} Hz</Typography>
                <Typography variant="body1">Volume: {currentLevel} dB below 0</Typography>
            </Box>
        </Box>
    );
};

export default HearingTest;
