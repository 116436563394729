import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HearingTestBasic from '../components/HearingTestBasic';
import HearingTestAdvanced from '../components/HearingTestAdvanced';
import ImprintStore from '../stores/ImprintStore';

const HearingTest = () => {
    const [testStarted, setTestStarted] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);
    const [currentLevel, setCurrentLevel] = useState(50);
    const [ear, setEar] = useState('left');
    const [withNoise, setWithNoise] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [pulseMode, setPulseMode] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [testFrequencies, setTestFrequencies] = useState([250, 500, 1000, 2000, 4000, 6000, 8000]);
    const [extendedActive, setExtendedActive] = useState(false);
    const [pulseActive, setPulseActive] = useState(false);
    const navigate = useNavigate();

    const audioContextRef = useRef(null);
    const oscillatorRef = useRef(null);
    const whiteNoiseSourceRef = useRef(null);
    const biquadFilterRef = useRef(null);

    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);
    useEffect(() => {
        if (testStarted) {
            playTestTone();
        }
    }, [currentFrequencyIndex, currentLevel, ear, withNoise, testStarted, pulseMode, testFrequencies]);

    const speak = (text, callback = null) => {
        if ('speechSynthesis' in window) {
            const msg = new SpeechSynthesisUtterance(text);
            msg.lang = 'en-US';
            msg.onend = callback;
            window.speechSynthesis.speak(msg);
        }
    };
    
    const playTone = (frequency, level) => {
        const audioContext = audioContextRef.current;
        if (!audioContext) return;

        oscillatorRef.current = audioContext.createOscillator();
        const gainNode = audioContext.createGain();
        const panner = audioContext.createStereoPanner();

        panner.pan.value = ear === 'left' ? -1 : 1;
        oscillatorRef.current.frequency.value = frequency;
        gainNode.gain.value = level / 100;

        oscillatorRef.current.connect(gainNode);
        gainNode.connect(panner);
        panner.connect(audioContext.destination);

        if (pulseMode) {
            const interval = setInterval(() => {
                gainNode.gain.setTargetAtTime(gainNode.gain.value === 0 ? level / 100 : 0, audioContext.currentTime, 0.03);
            }, 200);
            oscillatorRef.current.onended = () => clearInterval(interval);
        }

        oscillatorRef.current.start();
        setIsPlaying(true);
    };

    const stopTone = () => {
        if (oscillatorRef.current) {
            oscillatorRef.current.stop();
            oscillatorRef.current.disconnect();
            setIsPlaying(false);
        }
    };

    const playWhiteNoise = (currentEar, level) => {
        const audioContext = audioContextRef.current;
        if (!audioContext) return;

        whiteNoiseSourceRef.current = audioContext.createBufferSource();
        const bufferSize = audioContext.sampleRate * 3;
        const buffer = audioContext.createBuffer(1, bufferSize, audioContext.sampleRate);
        const data = buffer.getChannelData(0);

        for (let i = 0; i < bufferSize; i++) {
            data[i] = Math.random() * 2 - 1;
        }

        whiteNoiseSourceRef.current.buffer = buffer;
        whiteNoiseSourceRef.current.loop = true;

        const gainNode = audioContext.createGain();
        const panner = audioContext.createStereoPanner();
        biquadFilterRef.current = audioContext.createBiquadFilter();

        biquadFilterRef.current.type = 'bandpass';
        biquadFilterRef.current.frequency.value = testFrequencies[currentFrequencyIndex];
        biquadFilterRef.current.Q.value = Math.sqrt(2);

        panner.pan.value = currentEar === 'left' ? -1 : 1;
        gainNode.gain.value = currentLevel / 100;

        whiteNoiseSourceRef.current.connect(biquadFilterRef.current);
        biquadFilterRef.current.connect(gainNode);
        gainNode.connect(panner);
        panner.connect(audioContext.destination);

        if (pulseMode) {
            const interval = setInterval(() => {
                gainNode.gain.setTargetAtTime(gainNode.gain.value === 0 ? level / 100 : 0, audioContext.currentTime, 0.03);
            }, 200);
            whiteNoiseSourceRef.current.onended = () => clearInterval(interval);
        }

        whiteNoiseSourceRef.current.start();
    };

    const stopWhiteNoise = () => {
        if (whiteNoiseSourceRef.current) {
            whiteNoiseSourceRef.current.stop();
            whiteNoiseSourceRef.current.disconnect();
            setIsPlaying(false);
        }
    };

    const resetSound = () => {
        setCurrentFrequencyIndex(0);
        setCurrentLevel(50);
        stopWhiteNoise();
        stopTone();
    };

    const advanceTest = () => {
        if (currentFrequencyIndex < testFrequencies.length - 1) {
            setCurrentLevel(50);
            setCurrentFrequencyIndex(currentFrequencyIndex + 1);
            setTestStarted(true);
        } else {
            if (ear === 'right') {
                if (withNoise) {
                    endTestCycle();
                } else {
                    setEar('left');
                    setWithNoise(true);
                    resetSound();
                    setTestStarted(true);
                }
            } else {
                setEar('right');
                resetSound();
                setTestStarted(true);
            }
        }
    };

    const handleDecreaseVolume = () => {
        setCurrentLevel(prevLevel => {
            const newLevel = Math.max(prevLevel - 10, 0);
            stopTone();
            stopWhiteNoise();
            setTimeout(() => {
                playTestTone(newLevel);
            }, 100);
            return newLevel;
        });
    };

    const handleLevelChange = (event, newValue) => {
        setCurrentLevel(newValue);
        stopTone();
        stopWhiteNoise();
        setTimeout(() => {
            playTestTone(newValue);
        }, 100);
    };

    const handlePrevFrequency = () => {
        if (currentFrequencyIndex > 0) {
            setCurrentFrequencyIndex(currentFrequencyIndex - 1);
            setTimeout(() => playTestTone(), 500);
        }
    };

    const handleToggleTestMode = () => {
        setAdvanced(!advanced);
    };

    const handleStartTest = () => {
        resetSound();
        setEar('left');
        setWithNoise(false);
        speak('Starting the audio response evaluation', () => playTestTone());
    };
    
    const playTestTone = (level = currentLevel) => {
        stopTone();
        const frequency = testFrequencies[currentFrequencyIndex];
        playTone(frequency, level);

        if (withNoise) {
            stopWhiteNoise();
            stopTone();
            playWhiteNoise(ear, level);
        }
    };

    const handleMarkThresholdAndAdvance = () => {
        const testResult = {
            frequency: testFrequencies[currentFrequencyIndex],
            leftEar: ear === 'left' ? currentLevel : undefined,
            leftEarWithNoise: ear === 'left' && withNoise ? currentLevel : undefined,
            rightEar: ear === 'right' ? currentLevel : undefined,
            rightEarWithNoise: ear === 'right' && withNoise ? currentLevel : undefined
        };
        ImprintStore.addTestResult(testResult);
        advanceTest();
    };

    const endTestCycle = () => {
        speak('Test completed', () => {
            setTestStarted(false);
            setWithNoise(false);
            setEar('left');
            setCurrentFrequencyIndex(0);
            ImprintStore.saveTest().then(() => navigate('/view-imprint'));
        });
    };

    const extendPoints = () => {
        setTestFrequencies(prevFrequencies => {
            let updatedFrequencies = [...prevFrequencies];
            if (updatedFrequencies.includes(100)) {
                updatedFrequencies = updatedFrequencies.filter(freq => freq !== 125);
            }
            if (updatedFrequencies.includes(10000)) {
                updatedFrequencies = updatedFrequencies.filter(freq => freq !== 10000);
            }
            updatedFrequencies.unshift(100);
            updatedFrequencies.push(10000);
            return updatedFrequencies;
        });
        setExtendedActive(true);
        resetSound();
        setEar('left');
        setWithNoise(false);
        setCurrentFrequencyIndex(0);
        setTestStarted(true);
        speak('Extended points activated. Reinitializing Test', () => playTestTone());
    };
    
    const deactivateExtendedPoints = () => {
        setTestFrequencies(prevFrequencies => {
            return prevFrequencies.filter(freq => freq !== 125 && freq !== 10000);
        });
        setExtendedActive(false);
        resetSound();
        setEar('left');
        setWithNoise(false);
        setCurrentFrequencyIndex(0);
        setTestStarted(true);
        speak('Extended points deactivated. Reinitializing Test', () => playTestTone());
    };

    const pulseWave = () => {
        setPulseMode(true);
        setPulseActive(true);
        setSnackbarMessage('Pulse Mode Activated');
        setSnackbarOpen(true);
    };

    const deactivatePulseWave = () => {
        setPulseMode(false);
        setPulseActive(false);
        setSnackbarMessage('Pulse Mode Deactivated');
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
            {!advanced ? (
                <HearingTestBasic
                    onStartTest={handleStartTest}
                    onDecreaseVolume={handleDecreaseVolume}
                    onMarkThreshold={handleMarkThresholdAndAdvance}
                    testStarted={testStarted}
                    tone={testFrequencies[currentFrequencyIndex]}
                    ear={ear}
                    testAdvanced={() => setAdvanced(true)}
                    extendPoints={extendPoints}
                    pulseWave={pulseWave}
                    extendedActive={extendedActive}
                    pulseActive={pulseActive}
                    onExtendedDeactivate={deactivateExtendedPoints}
                    onPulseDeactivate={deactivatePulseWave}
                />
            ) : (
                <HearingTestAdvanced
                    onLevelChange={handleLevelChange}
                    onNextFrequency={handleMarkThresholdAndAdvance}
                    onPrevFrequency={handlePrevFrequency}
                    onToggleTestMode={handleToggleTestMode}
                    isFirstFrequency={currentFrequencyIndex === 0}
                    isLastFrequency={currentFrequencyIndex === testFrequencies.length - 1}
                    frequency={testFrequencies[currentFrequencyIndex]}
                    level={currentLevel}
                />
            )}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: 2,
                    zIndex: 9999,
                }}
            >
                <Typography variant="body1">Frequency: {testFrequencies[currentFrequencyIndex]} Hz</Typography>
                <Typography variant="body1">Volume: {currentLevel} dB below 0</Typography>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default HearingTest;
