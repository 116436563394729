import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Typography, IconButton, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import userStore from '../stores/UserStore'; // Adjust the path as necessary

const CheckVolumePage = observer(() => {
    const { userName, fetchUser } = userStore;

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: 'transparent',
                px: 2,
            }}
        >
            <Container maxWidth="sm" sx={{ textAlign: 'center', position: 'relative' }}>
                <IconButton
                    color="inherit"
                    component={Link}
                    to="/sign-up"
                    sx={{
                        position: 'absolute',
                        top: 16,
                        left: 16,
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: '30px' }} />
                </IconButton>
                <Box sx={{ mt: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        {userName ? `Thanks, ${userName}` : "Thanks"}
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                        We are super excited to work with you.
                    </Typography>
                    <Typography variant="h4" sx={{ mb: 5, fontWeight: 'bold' }}>
                        Make sure your volume is turned all the way up
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            component={Link}
                            to="//audio-response-evaluation"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'white',
                                color: 'black',
                                borderColor: 'transparent',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                '&:hover': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderColor: 'white',
                                },
                            }}
                        >
                            TEST HEARING
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
});

export default CheckVolumePage;
