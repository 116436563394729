import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  ThemeProvider,
  Alert,
  IconButton,
  Snackbar
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import authStore from '../stores/AuthStore';
import EarPhysicsTheme from '../themes/EarPhysicsTheme';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SignInPage = () => {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(null);
  const [identifierLabel, setIdentifierLabel] = useState('Email or Username');
  const navigate = useNavigate();

  useEffect(() => {
    if (emailRegex.test(formData.identifier)) {
      setIdentifierLabel('Email (you can also enter your username)');
    } else {
      setIdentifierLabel('Username or Email');
    }
  }, [formData.identifier]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validate = () => {
    const validationErrors = {};
    if (!formData.identifier) {
      validationErrors.identifier = 'Email or Username is required';
    }
    if (!formData.password) {
      validationErrors.password = 'Password is required';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      await authStore.login(formData.identifier, formData.password);
      navigate('/view-imprint');
    } catch (error) {
      setSubmissionError('Failed to sign in. Please check your email/username and password.');
    }
  };

  return (
    <ThemeProvider theme={EarPhysicsTheme}>
      <Container component="main" maxWidth="sm">
        <Box sx={{ mt: 4, mb: 2 }}>
          <IconButton color="inherit" component={Link} to="/">
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            Sign In
          </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ mb: 2 }}>
            Please enter your information to sign in.
          </Typography>
          {submissionError && <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{submissionError}</Alert></Snackbar>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="identifier"
              label={identifierLabel}
              name="identifier"
              autoComplete="identifier"
              value={formData.identifier}
              onChange={handleChange}
              error={Boolean(errors.identifier)}
              helperText={errors.identifier}
              InputLabelProps={{
                style: { color: '#FFFFFF' },
              }}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputLabelProps={{
                style: { color: '#FFFFFF' },
              }}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: 'white',
                color: 'black',
                borderColor: 'transparent',
                borderWidth: '1px',
                borderStyle: 'solid',
                '&:hover': {
                  backgroundColor: 'black',
                  color: 'white',
                  borderColor: 'white',
                },
              }}
            >
              Sign In
            </Button>
            <Button
              fullWidth
              variant="text"
              component={Link}
              to="/sign-up"
              sx={{
                mt: 1,
                color: 'white',
              }}
            >
              Don't have an account? Sign Up
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignInPage;
