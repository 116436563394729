export const toTitleCase = (str) => {
    if (!str) return '';
    const smallWords = new Set(['a', 'an', 'the', 'at', 'by', 'for', 'in', 'of', 'on', 'to', 'up', 'and', 'as', 'but', 'or', 'nor']);
    const wordSeparators = /([ :–—-])/;

    return str.split(wordSeparators)
        .map((word, index, array) => (
            smallWords.has(word.toLowerCase()) && index !== 0 && index !== array.length - 1 && array[index - 1] !== '-' && array[index + 1] !== '-' ?
            word.toLowerCase() :
            word.replace(/\b\w/, match => match.toUpperCase())
        ))
        .join('');
};