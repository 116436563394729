import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class ImprintStore {
  imprints = [];
  testResults = [];
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  addTestResult(testResult) {
    this.testResults.push(testResult);
  }

  async saveTest() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await apiHandler.post('/imprints', {
        tests: this.testResults,
        datetime: new Date()
      });
      runInAction(() => {
        if (response && response.data && response.data._id) {
          console.log('Test saved successfully:', response.data);
          this.testResults = [];
        } else {
          console.error('Unexpected response format:', response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.response ? error.response.data : error.toString();
        console.error('Failed to save test:', this.error);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchImprints() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await apiHandler.get('/imprints');
      runInAction(() => {
        if (response.data && Array.isArray(response.data)) {
          this.imprints = response.data.map(imprint => ({
            _id: imprint._id,
            owner: imprint.owner,
            onModel: imprint.onModel,
            datetime: imprint.datetime ? new Date(imprint.datetime) : new Date(),
            tests: imprint.tests && Array.isArray(imprint.tests) ? imprint.tests.map(test => ({
              frequency: test.frequency,
              leftEar: test.leftEar,
              leftEarWithNoise: test.leftEarWithNoise,
              rightEar: test.rightEar,
              rightEarWithNoise: test.rightEarWithNoise,
            })) : []
          }));
        } else {
          console.error('Unexpected response format:', response.data);
          this.imprints = [];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.response ? error.response.data : error.toString();
        console.error('Error fetching imprints:', this.error);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  clearError() {
    this.error = null;
  }

  clearTestResults() {
    this.testResults = [];
  }
}

export default new ImprintStore();
