import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../assets/images/ear-physics.png';
import FullWhite from '../assets/images/full-logo-white.png';
import { SwipeableDrawer } from '@mui/material';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['About', 'Contact', 'News', 'Privacy', 'Terms', 'Sign-up'].map((text, index) => (
          <ListItem button key={text} component={Link} to={`/${text.toLowerCase()}`}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <AppBar sx={{
      borderRadius: '10px',
      border: '1px solid #cdcdcd',
      top: '5px',
      left: '50%', 
      transform: 'translate3d(-50%, 0, 0)',
      boxShadow: '0px 32px 64px rgba(0,0,0,1)',
      position: 'fixed',
      zIndex: 1100,
      maxWidth: '90vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    }}>
      <Toolbar>
        <Button color="inherit" component={Link} to="/">
          <img src={FullWhite} alt="Ear Physics Logo" style={{ width: '100px', height: 'auto', margin: 'auto'}} />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', sm: 'flex' }}}>
          <Button color="inherit" component={Link} to="/about">About</Button>
          <Button color="inherit" component={Link} to="/contact">Contact</Button>
          <Button color="inherit" component={Link} to="/news">News</Button>
          <Button color="inherit" component={Link} to="/privacy">Privacy</Button>
          <Button color="inherit" component={Link} to="/terms">Terms</Button>
          <Button color="inherit" component={Link} to="/sign-in">Sign In</Button>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
