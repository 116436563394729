import React from 'react';
import PageLayout from '../components/PageLayout';
import StudioEarBudsImage from '../assets/images/00019-1130279688.png';
import CustomAudioImage from '../assets/images/00020-1130279688.png';
import HeadphoneGirl from '../assets/images/headphone-girl.png';
import ManConcert from '../assets/images/man-concert.png';
import Music from '../assets/images/music.png';

const sections = [
  {
    title: 'Revolutionizing Clarity',
    subheader: 'A New Era for Musicians',
    content: (
      <div>
        <p>For nearly a century, the nuanced needs of musicians were underserved by conventional audio response evaluations and correction devices. Ear Physics is pioneering a revolution in clarity, reshaping the landscape with precision-engineered in-ear monitors tailored for the auditory excellence musicians demand.</p>
      </div>
    ),
    image: StudioEarBudsImage, // Updated image for revolutionizing clarity
  },
  {
    title: 'Precision-Engineered Fit',
    subheader: 'Customized for Comfort and Sound',
    content: (
      <div>
        <p>Our custom-molded in-ear monitors are crafted for each individual's unique ear contours, providing unparalleled comfort and superior noise isolation. Musicians can immerse themselves in the purest sound, experiencing every note with crystalline clarity.</p>
      </div>
    ),
    image: CustomAudioImage, // Updated image for precision-engineered fit
  },
  {
    title: 'Focused on Musicians',
    subheader: 'Designed by Musicians, for Musicians',
    content: (
      <div>
        <p>Ear Physics is born from a synergy of acoustic expertise and a profound understanding of a musician’s needs. We are musicians serving musicians, ensuring that every in-ear monitor empowers artists to perform at their best.</p>
      </div>
    ),
    image: HeadphoneGirl, // Image showing focus on musicians
  },
  {
    title: 'Acoustic Engineering',
    subheader: 'Sound That Resonates with the Soul',
    content: (
      <div>
        <p>Our acoustic engineers blend art with science, creating in-ear monitors that convey every frequency with fidelity. From the deep resonance of a bass line to the high shimmer of a cymbal, your Ear Physics monitors are calibrated for live performance and studio sessions alike.</p>
      </div>
    ),
    image: ManConcert, // Image related to acoustic engineering
  },
  {
    title: 'Advancing Hearing Technology',
    subheader: 'Protect and Enhance Your Hearing',
    content: (
      <div>
        <p>While delivering superior sound quality, we are equally committed to protecting your hearing. Ear Physics monitors include built-in hearing protection features to guard against long-term damage without compromising the richness of your music.</p>
      </div>
    ),
    image: Music, // Image showcasing hearing technology
  },
];

const AboutPage = () => {
  return (
    <PageLayout pageTitle="ABOUT US" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default AboutPage;
