import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class UserStore {
    users = [];
    isLoading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    fetchUser = async () => {
        this.isLoading = true;
        this.error = null;
        try {
            const response = await apiHandler.get('/users');
            runInAction(() => {
                this.user = response.data;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error.message;
                console.error('Error fetching user:', error);
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    addUser = (user) => {
        this.users.push(user);
    }

    saveUser = async () => {
        this.isLoading = true;
        this.error = null;
        try {
            const response = await apiHandler.post('/User', this.users);
            runInAction(() => {
                this.users = response.data;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error.message;
                console.error('Error saving user:', error);
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    clearError = () => {
        this.error = null;
    }

    clearUser = () => {
        this.user = null;
    }

    get userName() {
        return this.user ? this.user.name : '';
    }
}

export default new UserStore();
