import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';
import EarPhysicsTheme from './themes/EarPhysicsTheme';
import NewsPage from './pages/NewsPage';
import TermsOfServicePage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import AboutPage from './pages/AboutPage';
import HearingTest from './pages/HearingTest';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import CheckVolumePage from './pages/CheckVolumePage';
import TestStateManagement from './pages/TestStateManagement';
import ViewImprint from './components/ViewImprint';

function App() {
  const ScrollToTop = () => {
    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    return null;
  };

    return (
      <ThemeProvider theme={EarPhysicsTheme}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="*" element={<LandingPage />} />
            <Route path="/terms" element={<TermsOfServicePage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/check-volume" element={<CheckVolumePage />} />
            <Route path="//audio-response-evaluation" element={<HearingTest />} />
            <Route path="/state-management-test" element={<TestStateManagement />} />
            <Route path="/view-imprint" element={<ViewImprint />} />
          </Routes>
        </Router>
      </ThemeProvider>
    );
}

export default App;
