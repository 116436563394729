import React from 'react';
import PageLayout from '../components/PageLayout';
import HeroCarousel from '../components/HeroCarousel';
import BeltPack from '../assets/images/belt-pack.png';
import Concert from '../assets/images/concert.png';
import IEMProduct from '../assets/images/belt-pack.png';
import IEM from '../assets/images/iem1.png';
import OverEar from '../assets/images/over-ear.png';
import WomanConcert from '../assets/images/woman-concert.png';
import Music from '../assets/images/music.png';
import ManConcert from '../assets/images/man-concert.png';
import Hearing from '../assets/images/hearing.png';
import IEMFront from '../assets/images/IEM-front.png';

const carouselSections = [
  {
    text: "In-Ear Monitors",
    subheader: "Experience precision and clarity with our professional in-ear monitoring systems.",
    image: BeltPack, // Image showcasing in-ear monitors
    button: { text: "Inquire Today", link: "/contact" },
  },
  {
    text: "Over-Ear Reference Headphones",
    subheader: "Coming Soon.  Discover studio-quality sound and ultimate comfort with our over-ear headphones.",
    image: OverEar, // Image showcasing over-ear headphones
  },
  {
    text: "High Fidelity On the Go",
    subheader: "Take studio-quality sound wherever life takes you.",
    image: Concert, // Image relevant to portable audio or live music settings
  },
  {
    text: "Custom Sound Personalization",
    subheader: "Configure your audio experience to match your unique hearing profile.",
    image: ManConcert, // Image showcasing sound personalization tech
    button: { text: "Take an audio response evaluation", link: "/sign-up" },
  }
];

const contentSections = [
  {
    title: "In-Ear Monitors",
    subheader: "Precision sound for professionals and audiophiles.",
    content: "Designed for the most demanding stage performances and audiophiles alike, our In-Ear Monitors offer unparalleled sound quality for an immersive audio experience. With advanced audio drivers and superior noise isolation, experience every beat and note in its purest form.",
    image: IEMFront,
  },
  {
    title: "Acoustic Mastery In-Ear Monitors",
    subheader: "Redefining Auditory Excellence for Artists and Enthusiasts.",
    content: "Our Acoustic Mastery In-Ear Monitors are the result of relentless innovation, providing an extraordinary balance of high-fidelity sound and ergonomic design. Ideal for both live performances and personal use, they offer an unmatched blend of comfort and audio clarity.",
    image: BeltPack,
  },
  {
    title: "Next-Gen In-Ear Monitoring",
    subheader: "Where Technology Meets Sound.",
    content: "Step into the future of sound with our next-gen In-Ear Monitors. These monitors set new standards in audio quality and user comfort, making them a top choice for musicians who demand reliability and exceptional sound reproduction on stage and in the studio.",
    image: Hearing,
  },
  {
    title: "Over-Ear Reference Headphones",
    subheader: "Coming Soon. Studio-grade clarity and comfort.",
    content: "Our Over-Ear Reference Headphones are engineered for the ultimate listening experience. They deliver studio-quality sound for professional audio work and the discerning ears of audiophiles. With ergonomic design and comfort for extended use, they provide an exceptional and immersive listening experience.",
    image: OverEar,
  },
  {
    title: "Hearing Normalization & Correction",
    subheader: "Advanced technology for your unique hearing signature.",
    content: "Leverage the revolutionary Ear Physics DSP technology to calibrate your audio experience precisely for your hearing needs. Experience sound like never before, tailored just for you, with our state-of-the-art hearing normalization and correction features.",
    image: Music,
  },
  {
    title: "High Fidelity On the Go",
    subheader: "Your sound, your way, anywhere.",
    content: "Our portable audio systems ensure that you don’t leave studio-quality sound behind. Enjoy the same crisp, detailed audio on tour, at home, or while traveling. These systems are designed for the audiophile on the move, delivering consistent, exceptional sound quality wherever you go.",
    image: Concert,
  },
  {
    title: "Custom Sound Personalization",
    subheader: "A personal audio engineer in your pocket.",
    content: "With our intuitive app, configure your listening experience with the tap of a finger. From soundstage adjustments to frequency response curves, create the sound you've always wanted. Our custom sound personalization puts the power of a studio in your hands.",
    image: WomanConcert, 
  },
];


const LandingPage = () => {
  return (
    <PageLayout pageTitle="Ear Physics - Your Hearing, Refined." sections={contentSections}>
      <HeroCarousel sections={carouselSections} />
    </PageLayout>
  );
};

export default LandingPage;
