import React, { useState, useEffect } from "react";
import { useSprings, animated } from "@react-spring/web";
import { Typography, Box, Button, useTheme, useMediaQuery } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSwipeable } from 'react-swipeable';

const HeroCarousel = ({ sections }) => {
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isTouchScreen = useMediaQuery('(hover: none) and (pointer: coarse)');
  const springs = useSprings(
    sections.length,
    sections.map((_, i) => ({
      opacity: i === index ? 1 : 0,
      transform: `translateX(${(i - index) * 100}%)`,
      config: { tension: 250, friction: 20 },
    }))
  );

  const goTo = (step) => {
    setIndex(
      (prevIndex) => (prevIndex + step + sections.length) % sections.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goTo(1);
    }, 7000);
    return () => clearInterval(interval);
  }, [index, sections.length]);

  const handlers = useSwipeable({
    onSwipedLeft: () => goTo(1),
    onSwipedRight: () => goTo(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Box
      {...handlers}
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        boxShadow: '200px 100px 200px rgba(20,25,25,1)',
      }}
    >
      {springs.map((props, i) => (
        <animated.div
          key={i}
          style={{
            ...props,
            position: "absolute",
            width: "100%",
            height: "100%",
            willChange: "opacity, transform",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              "::before": {
                content: '""',
                display: "block",
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${sections[i].image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              },
              "::after": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `radial-gradient(circle at center, transparent 65%, rgba(0,0,0,0.85) 100%)`,
              },
            }}
          />
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
              color: "common.white",
              p: 3,
              boxSizing: "border-box",
              textAlign: "center",
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{
                fontSize: "h3.fontSize",
              }}
            >
              {sections[i].text}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "h5.fontSize",
              }}
            >
              {sections[i].subheader}
            </Typography>
            {sections[i].button && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  py: 2,
                  px: 4,
                  fontSize: "1.25rem",
                  borderRadius: "50px",
                  border: "1px solid",
                  borderColor: "grey.300",
                  boxShadow: 6,
                  "&:hover": {
                    boxShadow: 8,
                  },
                  textTransform: "none",
                }}
                href={sections[i].button.link}
              >
                {sections[i].button.text}
              </Button>
            )}
          </Box>
        </animated.div>
      ))}
      {!isTouchScreen && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 2,
              left: theme.spacing(2),
            }}
          >
            <Button onClick={() => goTo(-1)} size="large">
              <NavigateBeforeIcon fontSize="large" />
            </Button>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 2,
              right: theme.spacing(2),
            }}
          >
            <Button onClick={() => goTo(1)} size="large">
              <NavigateNextIcon fontSize="large" />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default HeroCarousel;
