import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, Paper, Snackbar, Alert, Button } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ImprintStore from '../stores/ImprintStore';
import { useNavigate } from 'react-router-dom';


const ViewImprint = observer(() => {
    const [selectedImprint, setSelectedImprint] = useState(null);
    const [open, setOpen] = useState(false);
    const [activeLine, setActiveLine] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        ImprintStore.fetchImprints();
    }, []);

    useEffect(() => {
        if (ImprintStore.error) {
            setOpen(true);
        }
    }, [ImprintStore.error]);

    const handleClose = () => {
        setOpen(false);
    };

    if (ImprintStore.isLoading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
    }

    const imprints = toJS(ImprintStore.imprints) || [];

    const handleClick = (imprint) => {
        setSelectedImprint(imprint);
    };

    const formatDataForChart = (tests) => {
        if (!tests || !Array.isArray(tests)) {
            return [];
        }

        const frequencies = [125, 250, 500, 1000, 2000, 4000, 6000, 8000, 10000];
        const formattedData = frequencies.map(frequency => {
            const dataPoint = { frequency };
            tests.forEach(test => {
                if (test.frequency === frequency) {
                    if (test.leftEar !== undefined && test.leftEarWithNoise === undefined) dataPoint.leftEar = test.leftEar;
                    if (test.rightEar !== undefined && test.rightEarWithNoise === undefined) dataPoint.rightEar = test.rightEar;
                    if (test.leftEarWithNoise !== undefined) dataPoint.leftEarWithNoise = test.leftEarWithNoise;
                    if (test.rightEarWithNoise !== undefined) dataPoint.rightEarWithNoise = test.rightEarWithNoise;
                }
            });
            return dataPoint;
        });

        return formattedData;
    };

    const handleMouseEnter = (line) => {
        setActiveLine(line);
    };

    const handleMouseLeave = () => {
        setActiveLine(null);
    };

    return (
        <Box sx={{ maxWidth: '100%', p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Imprint Data
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button variant="contained" onClick={() => navigate('/audio-response-evaluation')}>
                    Take Test
                </Button>
            </Box>
            {imprints.length === 0 ? (
                <Typography variant="h6" color="textSecondary">
                    No imprints available. Please take a test to generate imprints.
                </Typography>
            ) : (
                <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>
                    <List>
                        {imprints.map((imprint, index) => (
                            <ListItem button key={index} onClick={() => handleClick(imprint)}>
                                <ListItemText primary={imprint.datetime ? new Date(imprint.datetime).toLocaleString() : 'Unknown Date'} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
            {selectedImprint && (
                <Box sx={{ height: 500, mt: 4}}>
                    <Typography variant="h6" gutterBottom>
                        Audiogram for {new Date(selectedImprint.datetime).toLocaleString()}
                    </Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={formatDataForChart(selectedImprint.tests)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="frequency" 
                                label={{ value: 'Frequency (Hz)', position: 'insideBottomRight', offset: -10 }} 
                                ticks={[100, 125, 250, 500, 1000, 2000, 4000, 6000, 8000, 10000, 12000]}
                                tick={{ fontSize: 10, fontFamily: 'Open Sans' }}
                                domain={[100, 12000]}
                            />
                            <YAxis 
                                label={{ value: 'dB HL (Volume)', angle: -90, position: 'insideLeft' }} 
                                domain={[70, -10]} 
                                ticks={[0, -10, -20, -30, -40, -50, -60, -70]}
                                tick={{ fontSize: 10, fontFamily: 'Open Sans' }}
                            />
                            <Tooltip />
                            <Legend verticalAlign="top" align="center" />
                            <Line 
                                type="monotone" 
                                dataKey="leftEar" 
                                name="Left Ear" 
                                stroke="#8884d8" 
                                activeDot={{ r: 8 }} 
                                strokeWidth={activeLine === 'leftEar' ? 4 : 2} 
                                onMouseEnter={() => handleMouseEnter('leftEar')} 
                                onMouseLeave={handleMouseLeave} 
                                dot={{ symbol: 'x', r: 4 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="rightEar" 
                                name="Right Ear" 
                                stroke="#ff7300" 
                                strokeWidth={activeLine === 'rightEar' ? 4 : 2} 
                                onMouseEnter={() => handleMouseEnter('rightEar')} 
                                onMouseLeave={handleMouseLeave} 
                                dot={{ symbol: 'o', r: 4 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="leftEarWithNoise" 
                                name="Left Ear with Noise" 
                                stroke="#82ca9d" 
                                strokeWidth={activeLine === 'leftEarWithNoise' ? 4 : 2} 
                                onMouseEnter={() => handleMouseEnter('leftEarWithNoise')} 
                                onMouseLeave={handleMouseLeave} 
                                dot={{ symbol: 'square', r: 4 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="rightEarWithNoise" 
                                name="Right Ear with Noise" 
                                stroke="#ff0000" 
                                strokeWidth={activeLine === 'rightEarWithNoise' ? 4 : 2} 
                                onMouseEnter={() => handleMouseEnter('rightEarWithNoise')} 
                                onMouseLeave={handleMouseLeave} 
                                dot={{ symbol: 'triangle', r: 4 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {ImprintStore.error ? ImprintStore.error.message || ImprintStore.error : 'Unknown error'}
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default ViewImprint;
