import React from 'react';
import { Box, Typography, Slider, Button, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const HearingTestAdvanced = ({
    onLevelChange,
    onNextFrequency,
    onPrevFrequency,
    onToggleTestMode,
    isFirstFrequency,
    isLastFrequency,
    frequency,
    level
}) => {
    return (
        <Box sx={{ padding: 32, textAlign: 'center' }}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Listen carefully for the sound at {frequency} Hz. Adjust the slider until you can no longer hear it.
            </Typography>

            <Box sx={{ width: '100%', paddingX: 2 }}>
                <Slider
                    value={level}
                    onChange={onLevelChange}
                    min={0}
                    max={80}
                    sx={{color: 'white'}}
                />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 2 }}>
                {!isFirstFrequency && (
                    <IconButton
                        onClick={onPrevFrequency}
                        color="primary"
                        size="small"
                    >
                        <ArrowLeft />
                    </IconButton>
                )}
                {!isLastFrequency && (
                    <IconButton
                        onClick={onNextFrequency}
                        color="primary"
                        size="small"
                    >
                        <ArrowRight />
                    </IconButton>
                )}
            </Box>
            
            <Button 
                onClick={onToggleTestMode} 
                variant="text" 
                sx={{ marginTop: 2 }}
                color="secondary"
            >
                Return to Basic Test
            </Button>
        </Box>
    );
};

export default HearingTestAdvanced;
