import React, { useState } from 'react';
import { Box, TextField, Grid, Button, Snackbar, Alert, Typography } from '@mui/material';
import PageLayout from '../components/PageLayout';
import ReCAPTCHA from 'react-google-recaptcha';
import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDSZBDK_yIIaUtyJCEnwrBkhQXrEObii90",
  authDomain: "ear-physics.firebaseapp.com",
  projectId: "ear-physics",
  storageBucket: "ear-physics.appspot.com",
  messagingSenderId: "95714786985",
  appId: "1:95714786985:web:cf12d89bda68c499fcb3b8",
  measurementId: "G-NJTY9ZV5LW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const ContactPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !message || !recaptchaValue) {
      setSnackbarMessage("Please fill all fields and complete the reCAPTCHA.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await addDoc(collection(firestore, "contacts"), {
        firstName,
        lastName,
        email,
        message
      });
      setSnackbarMessage("Message sent successfully!");
      setSnackbarSeverity('success');
      setFormSubmitted(true);
      setSnackbarOpen(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setRecaptchaValue('');
    } catch (error) {
      setSnackbarMessage("Error: " + error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const contactForm = formSubmitted ? (
    <Typography variant="h3" style={{ color: 'white', textAlign: 'center' }}>
      Thank you for contacting us. Your message has been sent successfully!
    </Typography>
  ) :  (
    <form onSubmit={handleSubmit}>
      <Box padding="25px 0">
        <Grid container spacing={2} style={{ marginBottom: "40px" }}>
          {/* First Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Last Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Email Input */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Message Input */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="How can we help?"
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#535353",
                  color: "#FFFFFF",
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* reCAPTCHA */}
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <ReCAPTCHA
              sitekey="6LdYIyYpAAAAAKQIToeyxPR5qqCxGE4c68NZs4wc"
              onChange={onRecaptchaChange}
            />
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12} style={{ textAlign: "left"}}>
            <Button type="submit" style={{color: 'white'}}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );

  return (
    <PageLayout pageTitle="Contact Us">
      {contactForm}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageLayout>
  );
};

export default ContactPage;
